import { useLiveQuery } from "dexie-react-hooks";
import { mainDb } from "../helpers/db.model";
import { useEffect, useState } from "react";

const camelize = (string: string) =>
	string.replace(/[-_]./g, (x) => x[1].toUpperCase());

/**
 *
 * @param keys - Array of keys that need to be found in the global config
 * @returns And object that consists all the keys and their values. The keys are in camelCase and the values are boolean
 */
export default function useGlobalConfig(
	keys: Array<string>,
	returnType: "boolean" | "value",
) {
	const globalConfig = useLiveQuery(() => mainDb.globalConfig.toArray());
	// console.log("globalConfig", globalConfig);

	const [configBoolean, setConfigBoolean] = useState<{
		[key: string]: boolean;
	} | null>(null);

	const [configValue, setConfigValue] = useState<{
		[key: string]: string | undefined | null;
	} | null>(null);

	useEffect(() => {
		if (globalConfig && keys.length) {
			for (let index = 0; index < keys.length; index++) {
				const key = keys[index];
				const keyData = globalConfig.find(
					(item: any) => item.key === key,
				);
				if (returnType === "value") {
					setConfigValue((prev) => ({
						...prev,
						[camelize(key)]: keyData ? keyData.value : null,
					}));
				} else if (returnType === "boolean") {
					setConfigBoolean((prev) => ({
						...prev,
						[camelize(key)]:
							keyData && keyData.value === "1" ? true : false,
					}));
				}
			}
		}
	}, [globalConfig, JSON.stringify(keys)]);

	return { configBoolean, configValue };
}
