import { Box, Divider, Typography, useTheme } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	checkPermission,
	getClinicCredentials,
	getClinicDomain,
	getLogoDimensions,
} from "../../../helpers/utils";
import useGlobalConfig from "../../../hooks/useGlobalConfig";
import { RootState } from "../../../redux/store";

import Icon from "../../icons/icon";

interface SideBarProps {
	isCollapsedTrue?: boolean;
}

function SideBar({ isCollapsedTrue }: SideBarProps) {
	// Theme selector
	const theme = useTheme();
	const { t } = useTranslation();
	const [isCollapsed, setIsCollapsed] = useState(
		isCollapsedTrue ? true : false,
	);
	const router = useRouter();

	const { configBoolean } = useGlobalConfig(
		["is-fax-referral-hide"],
		"boolean",
	);

	// console.log("configBoolean", configBoolean);

	const { role } = useSelector((state: RootState) => state.user);

	return (
		<Box
			className={isCollapsed ? "dtd w-96 overflow-hidden" : "dtd w-290"}
			sx={{ transition: "all 0.35s ease-in-out" }}
		>
			<Box
				className={
					isCollapsed ? "main-sidebar is-active" : "main-sidebar"
				}
				sx={{
					backgroundColor: theme.palette.tertiary2.main,
					transition: "all 0.35s ease-in-out",
					width: isCollapsed ? "64px" : "260px",
				}}
			>
				<Box
					className="flex justify-content-between gap-5"
					p={isCollapsed ? "16px 13px" : "16px"}
				>
					<Box>
						<Box
							className="icon-sideBar"
							sx={{
								backgroundColor: theme.palette.white.main,
								color: theme.palette.primary.main,
							}}
							onClick={() => setIsCollapsed(!isCollapsed)}
						>
							{isCollapsed ? (
								<Icon icon="cheveron-right" />
							) : (
								<Icon icon="grid" />
							)}
						</Box>
					</Box>
					<Box className="small-logo-wrap">
						<Link href="/">
							<Image
								className="small-logo"
								src={`${getClinicCredentials(
									"STATIC_CONTENT_BUCKET",
								)}/${getClinicDomain().toUpperCase()}/frontend_images/logo.svg`}
								width={getLogoDimensions("SMALL_LOGO", "WIDTH")}
								height={getLogoDimensions(
									"SMALL_LOGO",
									"HEIGHT",
								)}
								alt="Clinic Logo"
							></Image>
						</Link>
					</Box>
				</Box>
				<ul className="list-sidebar">
					{/* {checkPermission(role, "dashboard", "view") && (
						<li
							className={
								router.pathname == "/" ? "is-active" : ""
							}
						>
							<Link href="/"  >
								<Icon icon="home" />
								<Typography variant="body2" component="span">
									{t("Dashboard")}
								</Typography>
							</Link>
						</li>
					)} */}

					{configBoolean && !configBoolean.isFaxReferralHide && (
						<li
							className={
								router.pathname == "/fax-referrals"
									? "is-active"
									: ""
							}
						>
							<Link href="/fax-referrals">
								<Icon icon="print" />
								<Typography variant="body2" component="span">
									{t("Fax Referrals")}
								</Typography>
							</Link>
						</li>
					)}

					{checkPermission(role, "referrals", "view") && (
						<li
							className={
								router.pathname == "/referrals"
									? "is-active"
									: ""
							}
						>
							<Link href="/referrals">
								<Icon icon="add-person" />
								<Typography variant="body2" component="span">
									{t("Referrals")}
								</Typography>
							</Link>
						</li>
					)}

					{checkPermission(role, "leads", "view") && (
						<li
							className={
								router.pathname == "/leads" ? "is-active" : ""
							}
						>
							<Link href="/leads">
								<Icon icon="process-person" />
								<Typography variant="body2" component="span">
									{t("Leads")}
								</Typography>
							</Link>
						</li>
					)}
					{checkPermission(role, "patients", "view") && (
						<li
							className={
								router.pathname == "/patients"
									? "is-active"
									: ""
							}
						>
							<Link href="/patients">
								<Icon icon="check-person" />
								<Typography variant="body2" component="span">
									{t("Patients")}
								</Typography>
							</Link>
						</li>
					)}
				</ul>
				{checkPermission(role, "public-funded-waitlist", "view") && (
					<>
						<Divider sx={{ my: 1 }} />
						<ul className="list-sidebar">
							<li>
								<Link href="/public-funded-waitlist">
									<Icon icon="time" />
									<Typography
										variant="body2"
										component="span"
									>
										{t("Public Funded Waitlist")}
									</Typography>
								</Link>
							</li>
						</ul>
					</>
				)}
				{checkPermission(role, "referring-physician", "view") && (
					<>
						<Divider sx={{ my: 1 }} />
						<ul className="list-sidebar">
							<li>
								<Link href="/referring-physician/">
									<Icon icon="process-person" />
									<Typography
										variant="body2"
										component="span"
									>
										{t("Referring Physician")}
									</Typography>
								</Link>
							</li>
						</ul>
					</>
				)}

				<Divider sx={{ my: 1 }} />
				<ul className="list-sidebar">
					{checkPermission(role, "user-management", "view") && (
						<li
							className={
								router.pathname == "/user-management"
									? "is-active"
									: ""
							}
						>
							<Link href="/user-management/">
								<Icon icon="group" />
								<Typography variant="body2" component="span">
									{t("Team")}
								</Typography>
							</Link>
						</li>
					)}

					{/* {checkPermission(role, "configurations", "view") && (
						<li
							className={
								router.pathname == "/user-management"
									? "is-active"
									: ""
							}
						>
							<Link href="/"  >
								<SettingsIcon color="primary" />
								<Typography variant="body2" component="span">
									{t("Configurations")}
								</Typography>
							</Link>
						</li>
					)} */}
				</ul>
			</Box>
		</Box>
	);
}

export default React.memo(SideBar);
