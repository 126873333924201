import {
	Avatar,
	Box,
	Typography,
	Menu,
	MenuItem,
	useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../hooks/auth";
import Link from "next/link";

const Copyright = dynamic(() => import("../../organisms/Copyrights"), {
	ssr: false,
});

import { useTranslation } from "react-i18next";

import { Auth } from "aws-amplify";
import dynamic from "next/dynamic";
import { getClinicCredentials } from "../../../helpers/utils";
import CustomLoader from "../../CustomLoader";
import ZendeskWidget, { ZendeskWidgetHandles } from "../../zendeskWidget";
import Sidebar from "./Sidebar";
import { setSnackBarOptions } from "../../../redux/snackbar";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";

type DashboardLayoutProps = {
	children: React.ReactNode;
	showHeader?: boolean;
	showDropdown?: boolean;
	isCollapsedSidebar?: boolean;
};

const AppLayout = ({
	children,
	showHeader = false,
	showDropdown = false,
	isCollapsedSidebar = false,
}: DashboardLayoutProps) => {
	// Theme selector
	const theme = useTheme();
	const dispatch = useDispatch();
	const router = useRouter();
	const { isAuthenticated, logout } = useAuth();
	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [userDetails, setUserDetails] = useState<{
		firstName: string;
		lastName: string;
		email: string;
		profilePicture: string;
	} | null>(null);
	const open = Boolean(anchorEl);
	const handleClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
		try {
			setLoading(true);
			// Execute logout-related tasks
			await logout();
			await onLogoutZendesk();

			// Close the current session or modal
			handleClose();

			// Display success notification
			dispatch(
				setSnackBarOptions({
					open: true,
					message: t("You have been logged out successfully"),
					type: "success",
				}),
			);
		} catch (error) {
			console.error("Logout failed:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const getUserData = async () => {
			await Auth.currentAuthenticatedUser()
				.then((user) => {
					setUserDetails({
						firstName: user.attributes.given_name,
						lastName: user.attributes.family_name,
						email: user.attributes.email,
						profilePicture: user.attributes.picture
							? user.attributes.picture
							: "",
					});
				})
				.catch((error) => {
					console.log("Error in getting user details", error);
				});
		};

		getUserData();
	}, []);
	const { t } = useTranslation();

	const zendeskKey = getClinicCredentials("ZENDESK_SECRET_KEY");
	const zendeskRef = useRef<ZendeskWidgetHandles>(null);

	const onLogoutZendesk = () => {
		// Call the `doSomething` method of MyWidget
		zendeskRef.current?.logout();
	};

	if (isAuthenticated) {
		return (
			<>
				{loading && <CustomLoader open={loading} />}
				<Box
					sx={{
						padding: "16px 0",
						position: "relative",
						// overflow: "hidden",
					}}
				>
					<Box className="appLayoutElement">
						<svg
							className="blobSvg"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 500 500"
							id="blobSvg"
						>
							<path id="blob" fill={theme.palette.link.main}>
								<animate
									attributeName="d"
									dur="10000ms"
									repeatCount="indefinite"
									values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
								></animate>
							</path>
						</svg>
						<svg
							className="blobSvg1"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 500 500"
							id="blobSvg1"
						>
							<path id="blob" fill={theme.palette.secondary.main}>
								<animate
									attributeName="d"
									dur="10000ms"
									repeatCount="indefinite"
									values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
								></animate>
							</path>
						</svg>
					</Box>
					<Box className="dtbl">
						<Sidebar isCollapsedTrue={isCollapsedSidebar} />
						<Box className="dtd" p="0 0px 0 16px !important">
							<Box
								className={`top-bar flex justify-content-between ${
									showDropdown && !showHeader
										? "showDropdownActive"
										: ""
								}`}
								pr="32px"
								sx={{
									"&.showDropdownActive": {
										position: "absolute",
										right: "0px",
										top: "10px",
									},
								}}
							>
								{showDropdown && userDetails && (
									<>
										<Box
											sx={{
												width: 268,
											}}
										>
											<Box
												className="flex gap-10 cursor-pointer"
												id="basic-button"
												aria-controls={
													open
														? "basic-menu"
														: undefined
												}
												aria-haspopup="true"
												aria-expanded={
													open ? "true" : undefined
												}
												onClick={(
													e: React.MouseEvent<
														HTMLDivElement,
														MouseEvent
													>,
												) => {
													handleClick(e);
												}}
												component="div"
											>
												<Box
													sx={{
														flex: "0 0 calc(100% - 56px)",
														maxWidth:
															"calc(100% - 56px)",
														textAlign: "right",
													}}
												>
													<Typography
														className="fontWeight-medium"
														mb="3px"
														variant="body2"
														noWrap
													>
														{t("Hi")}{" "}
														{userDetails.firstName}{" "}
														{userDetails.lastName}
													</Typography>
													<Typography
														className="fontWeight-medium"
														variant="subtitle1"
														sx={{
															color: theme.palette
																.link.main,
															textTransform:
																"capitalize",
														}}
													>
														{t("My Account")}
													</Typography>
												</Box>
												<Avatar
													src={
														userDetails.profilePicture
															? userDetails.profilePicture
															: undefined
													}
													sx={{
														fontSize: 16,
													}}
												>
													{Array.from(
														userDetails.firstName,
													)[0] +
														Array.from(
															userDetails.lastName,
														)[0]}
												</Avatar>
											</Box>
											<Menu
												id="basic-menu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "right",
												}}
												MenuListProps={{
													"aria-labelledby":
														"basic-button",
												}}
											>
												<MenuItem
													sx={{
														p: 0,
														a: {
															p: "6px 16px",
														},
													}}
												>
													<Link
														href={"/your-profile"}
														onClick={handleClose}
													>
														{t("Your Profile")}
													</Link>
												</MenuItem>
												<MenuItem
													onClick={handleLogout}
												>
													{t("Logout")}
												</MenuItem>
											</Menu>
										</Box>
									</>
								)}
							</Box>
							<Box pr="32px">
								{children}
								<Copyright />
							</Box>
						</Box>
					</Box>
				</Box>
				<ZendeskWidget zendeskKey={zendeskKey} ref={zendeskRef} />
			</>
		);
	}

	return (
		<>
			<CustomLoader open={loading} />
		</>
	);
};

export default React.memo(AppLayout);
